


































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Collection, View, ComponentType, Validation, ValidationType } from '@/models'
import PaginatedList from '@/components/PaginatedList.vue'
import ComponentCreateDialog from '@/components/ComponentCreateDialog.vue'
import { CreateElement } from 'vue'
import gql from 'graphql-tag'
import * as ComponentTypes from '..'

@Component({
  components: {
    PaginatedList,
    ComponentCreateDialog
  },
  apollo: {
    validationTypes: gql`query {
      validationTypes {
        _id
        name
      }
    }`
  }
})
export default class AdminComponentsValidationsList extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId !: string
  /** Current component type name */
  @Prop({ type: String, required: true }) componentTypeName !: string

  validationTypes !: ValidationType[]

  /** Current search query */
  searchQuery = ''
  /** Current sorting mode */
  sortBy = 'name'
  /** Create modal state */
  createModalOpen = false

  /** Current component type */
  get componentType () : ComponentType<any> {
    return (ComponentTypes as Record<string, ComponentType<any>>)[this.componentTypeName]
  }

  transformResult (input : Validation) {
    const typeDef = this.validationTypes.find(t => t._id === input.validationTypeId)
    return {
      ...this.componentType.transformResult(input),
      subtitle: typeDef ? typeDef.name : input.validationTypeId
    }
  }

  /** Open or close the creation modal */
  toggleCreateModal (value : any) {
    this.createModalOpen = value != null && typeof value === 'boolean' ? value : !this.createModalOpen
  }

  handleCreate (component : any) {
    if (component && component._id) {
      return this.$router.push(`${this.componentType.namespace}/${component._id}`)
    }
  }
}
